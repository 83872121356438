'use client';

import Image from 'next/image';
import { Typography, Box, Container, Grid2, Stepper, StepLabel, StepContent, Step, StepConnector, Stack } from '@mui/material';
import { UmbrellaButton } from '../_components/UmbrellaButton';
import imgGetStartedSteps from './assets/img-getStartedSteps.png';
import { getStartedStepsData } from './getStartedSteps.data';
export const GetStartedSteps = () => {
  const title = 'Get started with Quotebeam in 3 Easy Steps:';
  return <Box sx={{
    background: 'linear-gradient(90deg, #E6E2FA 0%, rgba(247, 237, 255, 0.00) 100%)'
  }} data-sentry-element="Box" data-sentry-component="GetStartedSteps" data-sentry-source-file="GetStartedSteps.tsx">
      <Container sx={{
      py: [4, 4, 11, 15]
    }} data-sentry-element="Container" data-sentry-source-file="GetStartedSteps.tsx">
        <Grid2 container columnSpacing={3} sx={{
        alignItems: 'center'
      }} data-sentry-element="Grid2" data-sentry-source-file="GetStartedSteps.tsx">
          <Grid2 size={{
          xs: 12,
          md: 6
        }} data-sentry-element="Grid2" data-sentry-source-file="GetStartedSteps.tsx">
            <Typography variant="h3" sx={theme => ({
            mb: 6.75,
            [theme.breakpoints.down('lg')]: {
              fontSize: 18,
              mb: 4
            }
          })} component="h2" data-sentry-element="Typography" data-sentry-source-file="GetStartedSteps.tsx">
              {title}
            </Typography>
            <Stepper orientation="vertical" connector={<StepConnector sx={{
            ml: [2.125, 2.625]
          }} />} sx={{
            pl: 1.5,
            columnGap: 0
          }} data-sentry-element="Stepper" data-sentry-source-file="GetStartedSteps.tsx">
              {getStartedStepsData.map((step, index) => <Step active key={step.title}>
                  <StepLabel sx={{
                pb: 0,
                alignItems: 'start',
                '.MuiStepLabel-iconContainer': {
                  pr: 4
                }
              }} slotProps={{
                stepIcon: {
                  icon: <Typography sx={{
                    fontSize: 20,
                    color: 'secondary.contrastText',
                    bgcolor: 'secondary.main',
                    borderRadius: '50%',
                    width: [35, 43],
                    height: [35, 43],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }} variant="bodyMedium">
                            {index + 1}.
                          </Typography>
                }
              }}>
                    <Typography variant="h5" component="h3">
                      {index + 1}. {step.title}
                    </Typography>
                  </StepLabel>
                  <StepContent sx={{
                mt: 1,
                ml: [2.125, 2.625],
                pl: [4 + 2.125, 4 + 2.625],
                pr: [0, 6],
                '& .MuiStepContent-transition': {
                  position: 'relative',
                  top: -16
                }
              }}>
                    <Typography variant="bodyLarge" sx={{
                  color: 'text.primary'
                }}>
                      {step.description}
                    </Typography>
                  </StepContent>
                </Step>)}
            </Stepper>
            <Stack direction={{
            xs: 'column',
            md: 'row'
          }} sx={{
            gap: [2, 4],
            mt: 3,
            ml: [3, 4.75, 10.75],
            mr: [3, 4.75]
          }} data-sentry-element="Stack" data-sentry-source-file="GetStartedSteps.tsx">
              <UmbrellaButton href="/get-a-demo" fullWidth variant="outlined" data-sentry-element="UmbrellaButton" data-sentry-source-file="GetStartedSteps.tsx">
                Talk to Sales
              </UmbrellaButton>
              <UmbrellaButton href="https://get.quotebeam.com/personalized-demo/" fullWidth variant="contained" data-sentry-element="UmbrellaButton" data-sentry-source-file="GetStartedSteps.tsx">
                Get a Demo
              </UmbrellaButton>
            </Stack>
          </Grid2>
          <Grid2 size={{
          xs: 12,
          md: 6
        }} sx={{
          mt: [4, 4, 0]
        }} data-sentry-element="Grid2" data-sentry-source-file="GetStartedSteps.tsx">
            <Image loading="eager" src={imgGetStartedSteps.src} width={imgGetStartedSteps.width} height={imgGetStartedSteps.height} alt={title} sizes="(max-width: 600px) 100vw, 600px" style={{
            width: '100%',
            height: 'auto'
          }} data-sentry-element="Image" data-sentry-source-file="GetStartedSteps.tsx" />
          </Grid2>
        </Grid2>
      </Container>
    </Box>;
};